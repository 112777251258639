@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Axiforma Regular';
	font-weight: 400;
	font-style: normal;
	src: url('/public/fonts/Axiforma-Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Axiforma Bold';
	font-weight: bold;
	font-style: normal;
	src: url('/public/fonts/Axiforma-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'Axiforma Light';
	font-weight: lighter;
	font-style: normal;
	src: url('/public/fonts/Axiforma-Light.woff2') format('woff2');
}

@layer base {
	html {
		font-family: Axiforma Regular, system-ui, sans-serif;
		font-weight: 400;
	}
	body {
		overflow-x: hidden;
	}
}
@layer components {
}
